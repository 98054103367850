export const URLAPI = "https://back.csm.orocash.ec";

export const WEBSOCKET = 'wss://back.csm.orocash.ec';

export const KEY_MAPS = 'AIzaSyD7PLL5ULCA4UAvLrxxPynSjVbjaBjki1s';

export const PROVINCES_PATH = "/provinces/list/";
export const PROVINCE_CREATE_PATH = "/provinces/create/";
export const PROVINCE_UPDATE_PATH = "/provinces/update/";

export const BANKS_PATH = "/checks/list-bank/";
export const BANK_CREATE_PATH = "/checks/create-bank/";
export const BANK_UPDATE_PATH = "/checks/update-bank/";

export const SOCIETIES_PATH = "/societies/list/";
export const SOCIETY_CREATE_PATH = "/societies/create/";
export const SOCIETY_UPDATE_PATH = "/societies/update/";

export const CHECKS_PATH = "/checks/list/";
export const CHECK_CREATE_PATH = "/checks/create/";
export const CHECK_UPDATE_PATH = "/checks/update/";

export const ZONES_PATH = "/zones/list/";
export const ZONE_CREATE_PATH = "/zones/create/";
export const ZONE_UPDATE_PATH = "/zones/update/";

export const CITIES_PATH = "/cities/list/";
export const CITY_CREATE_PATH = "/cities/create/";
export const CITY_UPDATE_PATH = "/cities/update/";

export const STORES_PATH = "/stores/list/";
export const STORE_CREATE_PATH = "/stores/create/";
export const STORE_UPDATE_PATH = "/stores/update/";

export const ASSIST_CONTROL_PATH = "/assist-control/list/";
export const MARKINGS_PATH = "/assist-control/markings/";
export const ASSIST_CONTROL_CREATE_PATH = "/assist-control/create/";
export const ASSIST_CONTROL_UPDATE_PATH = "/assist-control/update/";

export const TASK_TYPES_PATH = "/task/types/list/";
export const TASK_TYPE_CREATE_PATH = "/task/type/create/";
export const TASK_TYPE_UPDATE_PATH = "/task/type/update/";

export const TASK_PATH = "/task/list/";
export const TASK_LIST_PATH = "/task/task-list/";
export const TASK_LIST_CREATE_PATH = "/task/task-list/create/";
export const TASK_CREATE_PATH = "/task/create/";
export const TASK_LIST_UPDATE_PATH = "/task/task-list/update/";
export const TASK_UPDATE_PATH = "/task/update/";
export const TASK_TEMPLATE_DELETE_PATH = "/task/template/delete/";

export const SCHEDULES_PATH = "/schedules/list/";
export const SCHEDULE_CREATE_PATH = "/schedules/create/";
export const SCHEDULE_UPDATE_PATH = "/schedules/update/";
export const TOKEN_PATH = "/user/token/";
export const USERS_PATH = "/user/list/";
export const USERS_UPDATE_PATH = "/user/update/";
export const USERS_UPDATE_PASSWORD_PATH = "/user/update-password/";
export const USERS_CREATE_PATH = "/user/create/";
export const USER_GROUPS_PATH = "/user/group-list/";
export const USER_GET_PATH = "/user/get/";
export const USER_GET_ALL_PERMISSIONS_PATH = '/user/permission-list/';
export const USER_CREATE_GROUP_PATH = '/user/group-create/';
export const USER_UPDATE_GROUP_PATH = '/user/group-update/';
export const USER_ACTIVATE_PATH = '/user/activate/';
export const USER_LOGOUT_PATH = '/user/logout/';
export const USER_PASSWORD_RESET_PATH = '/user/password/reset/';
export const USER_PASSWORD_RESET_CONFIRM_PATH = '/user/password/reset/confirm/';
export const USER_TYPE_PATH = '/user/type/';
export const TASK_SCHEDULED_PATH = '/task/scheduled/list/';
export const CREATE_TASK_SCHEDULED_PATH = '/task/scheduled/create/';
export const UPDATE_TASK_SCHEDULED_PATH = '/task/scheduled/update/';
export const RESCHEDULED_TASK_SCHEDULED_PATH = '/task/scheduled/rescheduled/';
export const CONFIRM_TASK_SCHEDULED_PATH = '/task/scheduled/confirm/';
export const CONFIRM_SKIP_TASK_SCHEDULED_PATH = '/task/scheduled/skip/confirm/';
export const CANCEL_TASK_SCHEDULED_PATH = '/task/scheduled/cancel/';
export const CREATE_LOCATION_TASK_SCHEDULED_PATH = '/task/scheduled/location/';
export const TASK_RESOURCES_PATH = '/task/scheduled/resources/';
export const TASK_SCHEDULED_ADMIN_PATH = '/task/scheduled/list/admin/';
export const TASK_SCHEDULED_EXPORT_PATH = '/task/scheduled/export/';
export const TASK_SCHEDULED_REPORT_CHECK_PATH = '/task/scheduled/report/check/';
export const TASK_SCHEDULED_ITEM_PATH = '/task/scheduled/item/admin/';
export const ACTIVITIES_PATH = '/task/scheduled/activities/';
export const ACTIVITIES_DETAIL_PATH = '/task/scheduled/activities/detail/';
export const ACTIVITIES_AGENDA_PATH = '/task/scheduled/activities/agenda/';
export const ACTIVITIES_MONTHLY_PATH = '/task/scheduled/activities/monthly/';
export const NOTIFICATIONS_TASK_PATH = '/task/notifications/';
export const UPDATE_NOTIFICATIONS_TASK_PATH = '/task/notifications/update/';

export const GET_LOG_TASK_SCHEDULED_PATH = '/task/scheduled/log/';
export const GET_LOG_HISTORY_TASK_SCHEDULED_PATH = '/task/scheduled/log/history/';
export const CREATE_LOG_TASK_SCHEDULED_PATH = '/task/scheduled/log/create/';

export const OBSERVATION_PATH = "/task/observation/list/";
export const OBSERVATION_CREATE_PATH = "/task/observation/create/";
export const OBSERVATION_UPDATE_PATH = "/task/observation/update/";

export const SKIP_TASK_PATH = "/task/skip/list/";
export const SKIP_TASK_CREATE_PATH = "/task/skip/create/";
export const SKIP_TASK_UPDATE_PATH = "/task/skip/update/";

export var URL_GEOLOCALIZATION = 'https://nominatim.openstreetmap.org/reverse';

export const APP_NAME = "CSM";


