import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import Footer from "../../../components/footer";
import Breadcrumbs from "../../../components/breadcrumbs";
import {URLAPI} from "../../../config";
import LoadingSpinner from "../../../components/loading-spinner";
import Input from "../../../components/input";
import {searchTaskReources} from '../../../redux/task_scheduled';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SelectInput from '../../../components/select';
import {searchTask} from "../../../redux/task";


const TaskResources = (props) => {
    const {token} = useSelector((state) => state.users_module.login.data) || null;
        

    const [isLoading, setIsLoading] = useState(false);

    const [datas, setDatas] = useState([]);
    const [usuario, setUsuario] = useState('');
    const [id_tarea, setIdTarea] = useState(0);
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const [fecha_inicial, setFechaInicial] = useState('');
    const [fecha_final, setFechaFinal] = useState('');

    const _get_all_users = useSelector(
        (state) => state.users_module._get_all_users
    );

    useEffect(() => {
        if (_get_all_users.data) {
            setUsers(_get_all_users.data);
        }
    }, [_get_all_users]);

    const handleChange = (e) => {
        setUsuario(e.target.value);
        setIdTarea(0);
        document.getElementById("id_tarea").selectedIndex = 0;
        getTask(e.target.value, token);
        setDatas([]);
    }

    async function getTask(user, token) {
        setIsLoading(true);
        const params = '?usuario=' + user;
        const response = await searchTask(token, params);
        setTasks(response.data.datos);
        setIsLoading(false);
    }

    const taskReources = async (params) => {
        const response = await searchTaskReources(token, params);
        if (response && response.data && response.data.datos) {
            setDatas(response.data.datos);
        }
        setIsLoading(false);
    }


    function search_activities_agenda() {
        setIsLoading(true);
        if (usuario === "") {
            toast.error("Debe seleccionar un usuario", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (id_tarea === 0) {
            toast.error("Debe seleccionar una tarea", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (fecha_inicial === "") {
            toast.error("Debe seleccionar la fecha inicial", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (fecha_final === "") {
            toast.error("Debe seleccionar la fecha final", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        let variants = "?usuario=" + usuario + '&&id_tarea=' + id_tarea + '&&fecha_inicio=' + fecha_inicial + '&&fecha_fin=' + fecha_final;
        taskReources(variants);
    }
    

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            {isLoading && <LoadingSpinner/>}
            <div><ToastContainer/></div>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Actividades"
                            items={[{label: "Consulta de archivos", link: "/archivos"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row m-1">
                                    <div className="col-12">
                                        <div className="border_card">
                                            <p className="ml-1" style={{marginTop: 10, fontSize: 18, color: '#727E8C'}}>Bussqueda de archivo por tarea</p>
                                        </div>
                                    </div>
                                    <div className=" col-md-3 col-sm-6 col-12">
                                        <label>Usuario</label>
                                        <SelectInput
                                            id="usuario"
                                            name="usuario"
                                            value={usuario}
                                            onChange={handleChange}
                                            options={users ? users.map((item) => ({
                                                name: item.nombres + ' ' + item.apellidos,
                                                id: item.usuario,
                                            })) : []}
                                        />
                                    </div>
                                    <div className=" col-md-3 col-sm-6 col-12">
                                        <label>Tarea</label>
                                        <SelectInput
                                            id="id_tarea"
                                            name="id_tarea"
                                            value={id_tarea}
                                            onChange={(e) => {setIdTarea(parseInt(e.target.value))}}
                                            options={tasks ? tasks.map((item) => ({
                                                name: item.nombre,
                                                id: item.id,
                                            })) : []}
                                        />
                                    </div>
                                    <div className=" col-md-3 col-sm-6 col-12">
                                        <Input
                                            name="fecha_inicial"
                                            label="Desde"
                                            type="date"
                                            value={fecha_inicial}
                                            onChange={(e) => {setFechaInicial(e.target.value)}}
                                        />
                                    </div>
                                    <div className=" col-md-3 col-sm-6 col-12">
                                        <Input
                                            name="fecha_inicio"
                                            label="Hasta"
                                            type="date"
                                            value={fecha_final}
                                            onChange={(e) => {setFechaFinal(e.target.value)}}
                                        />
                                    </div>
                                    <div className=" col-12 text-center">
                                        <button type='button' onClick={() => {search_activities_agenda()}} className='btn btn-calendar'>Buscar</button>
                                    </div>
                                    
                                    <div className='col-12'>
                                        {datas && 
                                            <table className='table table-hover'>
                                                <thead>
                                                    <th colSpan={4}><h5 className="text-bold-700">Archivos encontrados</h5></th>
                                                </thead>
                                                <tbody>
                                                    {datas.map((x) => (
                                                        <tr>
                                                            <th><small>Tienda</small><br/>{x.tienda}</th>
                                                            <th><small>Fecha</small><br/>{x.fecha}</th>
                                                            <th><small>Archivo</small><br/>{x.nombre_archivo}</th>
                                                            {/*eslint-disable-next-line */}
                                                            <th className='text-center'><a href={URLAPI + x.archivo} target="_blank">Descargar</a></th>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default TaskResources;
